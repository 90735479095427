import { mapActions, mapMutations } from 'vuex'

import { cnpj, cpf } from 'cpf-cnpj-validator'

import { StatusAgendamento } from '@/utils/agendamento'

export const methods = {
  ...mapActions('fornecedores', ['getFornecedores']),
  ...mapActions('clientes', ['getClientes']),
  ...mapActions('transportadoras', ['getTransportadoras']),
  ...mapActions('contratos', ['getContratos']),
  ...mapActions('patio', [
    'getRegioes',
    'getRepresentantes',
    'getTipoOperacao',
    'getServicos',
  ]),
  ...mapActions('motoristas', ['getMotoristas']),
  ...mapMutations('relatorioCargaDescarga', ['UPDATE_PARAMS']),

  handleFixParams(fixedParams, [key, param]) {
    if (param.value) {
      const paramsWithCNPJ = []
      const paramsWithCPF = ['representante', 'motorista']
      const paramsWithPublicId = ['fornecedor', 'cliente']
      const paramsProdutoWithId = ['produto']
      const paramsSubProdutoWithId = ['sub_produto']

      if (param.until == true) {
        if (param.value.trim().split(' ').length == 1) {
          param.value += ' 23:59:59'
        } else if (param.value.trim().split(' ')[1].split(':').length == 2) {
          param.value += ':59'
        }
      }
      if (paramsWithCNPJ.includes(key)) {
        fixedParams[key] = cnpj.strip(param.value)
      } else if (paramsWithCPF.includes(key)) {
        fixedParams[key] = cpf.strip(param.value)
      } else if (paramsWithPublicId.includes(key)) {
        if (key === 'fornecedor') {
          fixedParams[key] = this.params.fornecedor.value.fornecedor_id
        } else {
          fixedParams[key] = param.value
        }
      } else if (paramsProdutoWithId.includes(key)) {
        fixedParams[key] = this.params.produto.value.id
      } else if (paramsSubProdutoWithId.includes(key)) {
        fixedParams[key] = this.params.sub_produto.value.id
      } else {
        fixedParams[key] = param.value
      }
    }

    return fixedParams
  },
  fixParams() {
    return Object.entries(this.params).reduce(
      this.handleFixParams,
      new Object()
    )
  },

  search() {
    if (!this.areSettingsEmpty) {
      const fixedParams = {
        ...this.fixParams(),
        status: StatusAgendamento.CONCLUIDO,
      }

      if (this.servico) {
        fixedParams.servico = this.servico
      }

      this.UPDATE_PARAMS(fixedParams)
      this.$emit('search')
    }
  },

  async showOnlySelectedOptionsAndGetItems() {
    this.selectedOptions.forEach((option) => {
      if (this.params[option]) {
        this.params[option].visible = true
      }
    })
    this.showDaddy()
  },

  showDaddy() {
    for (let [daddy, dependents] of Object.entries(this.dependents)) {
      const isSomeDependentVisible = dependents.some(
        (dependent) => this.params[dependent].visible
      )
      if (isSomeDependentVisible) {
        this.params[daddy].visible = true
      }
    }
  },

  async getCustomTransbordoDownload() {
    const params = {
      ...this.getDefaultParams(),
    }
    try {
      await this.$emit('customTransbordoDownload', params)
    } catch (error) {
      console.error('Error in getCustomTransbordoDownload:', error)
    }
  },
  async gateSelected(gate) {
    this.gate.servicos = []
    let servicos = []
    if (gate == this.empresaAtual.public_id) {
      servicos = await this.getServicos({
        proprietario: this.gate.public_id,
      })
    } else {
      const fornecedores = await this.getFornecedores(this.gate)
      const fornecedor = fornecedores.find(
        (i) => i.fornecedor_id == this.empresaAtual.public_id
      )
      if (fornecedor) {
        servicos = fornecedor.servicos_autorizados.map((i) => i.servico)
      }
    }
    this.servicos = servicos
  },
}
