export const props = {
  tela: {
    type: String,
    required: true,
  },
  headers: {
    type: Array,
    required: true,
  },
  data: {
    type: Array,
    required: true,
  },
  dataTotal: {
    type: Number,
    required: true,
  },
  pageSize: {
    type: Number,
    required: true,
  },
  canDownload: {
    type: Boolean,
    default: false,
  },
  canDownloadNavio: {
    type: Boolean,
    default: false,
  },
  canDownloadCarga: {
    type: Boolean,
    default: false,
  },
  canDownloadTransbordo: {
    type: Boolean,
    default: false,
  },
  canDownloadRodoviario: {
    type: Boolean,
    default: false,
  },
  canDownloadExpedicaoRecebimento: {
    type: Boolean,
    default: false,
  },
  canDownloadExpedicaoFabrica: {
    type: Boolean,
    default: false,
  },
  isRelatorio: {
    type: Boolean,
    default: false,
  },
}
