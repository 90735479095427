import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/index'
import store from './store/index'
import Vuebar from 'vuebar'
import { Plugin } from 'vue-fragment'
import './plugins/base'
import './plugins/axios'
import './plugins/veevalidate'
import './plugins/toasted'
import VuetifyMoney from 'vuetify-money'

import { abilitiesPlugin } from '@casl/vue'
import { collaboratorAbility, tenantAbility } from './plugins/casl'

import firebaseMessaging from './firebase'

Vue.prototype.$messaging = firebaseMessaging

Vue.use(abilitiesPlugin, collaboratorAbility)
Vue.use(abilitiesPlugin, tenantAbility)

import { Laue } from 'laue'
Vue.use(Laue)

import VueSkycons from 'vue-skycons'
Vue.use(VueSkycons, {
  color: '#1e88e5',
})

Vue.use(Plugin)

Vue.config.productionTip = false
Vue.use(Vuebar)

import DatetimePicker from 'vuetify-datetime-picker'
Vue.use(DatetimePicker)

import JsonExcel from 'vue-json-excel'

Vue.component('downloadExcel', JsonExcel)

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import { VueMaskFilter } from 'v-mask'
Vue.filter('VMask', VueMaskFilter)

import moment from 'moment'
Vue.use(moment)
Vue.use(VuetifyMoney)

// qrcode
import VueQrcode from '@chenfengyuan/vue-qrcode'
Vue.component(VueQrcode.name, VueQrcode)

// i18n
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import ptBR from './lang/pt-BR'

const i18n = new VueI18n({
  locale: 'pt-br',
  messages: {
    'pt-br': ptBR,
  },
})

Vue.filter('upper', (str) => {
  if (typeof str === 'string') {
    return str.toUpperCase()
  }

  return str
})

export const _app = new Vue({
  i18n,
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount('#app')
