<template>
  <div>
    <resumo :resumo="resumo" :permission="permission" />
    <listagem
      ref="listagem"
      :tela="permission"
      :headers="headers.filter((item) => item.display !== false)"
      :data="agendamentos"
      :dataTotal="dataTotal"
      :pageSize="page_size"
      @handleGet="handleGet"
      @getDownload="download"
      @getCustomDownload="getCustomDownload"
      @getCustomCargaDownload="getCustomCargaDownload"
      @getCustomTransbordoDownload="getCustomTransbordoDownload"
      @getCustomRodoviarioDownload="getCustomRodoviarioDownload"
      @getCustomExpedicaoRecebimentoDownload="
        getCustomExpedicaoRecebimentoDownload
      "
      @getCustomAcertoPesoDownload="getCustomAcertoPesoDownload"
      @getCustomExpedicaoFabricaDownload="getCustomExpedicaoFabricaDownload"
      :canDownload="canDownload"
      :canDownloadNavio="canDownloadNavio"
      :canDownloadCarga="canDownloadCarga"
      :canDownloadTransbordo="canDownloadTransbordo"
      :canDownloadRodoviario="canDownloadRodoviario"
      :canDownloadExpedicaoRecebimento="canDownloadExpedicaoRecebimento"
      :canDownloadExpedicaoFabrica="canDownloadExpedicaoFabrica"
      :isRelatorio="true"
    />
  </div>
</template>

<script>
import Listagem from '@/components/patio/GerenciamentoServico/listagem/Componente.vue'
import Resumo from '@/components/patio/GerenciamentoServico/Resumo.vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'
import { getUrl, downloadUrl } from '@/utils/index'

export default {
  name: 'RelatorioCargaDescargaListConcluidos',
  props: {
    headers: {
      type: Array,
      required: true,
    },
  },
  components: {
    Listagem,
    Resumo,
  },
  data() {
    return {
      page_size: 15,
      dataTotal: 0,
      resumo: undefined,
    }
  },
  computed: {
    ...mapState('patio', ['agendamentos', 'regioes']),
    ...mapState('auth', ['empresaAtual', 'user']),
    ...mapState('relatorioCargaDescarga', ['params']),
    permission() {
      return Sequences.CargaDescarga.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canDownload() {
      return Object.keys(this.params).length > 0
    },
    canDownloadNavio() {
      return this.canDownload && this.params.navio
    },
    canDownloadCarga() {
      return this.canDownload && this.params.tipo_operacao == '1'
    },
    canDownloadTransbordo() {
      return (
        this.canDownload && this.params.servico_triado === 'Transbordo Interno'
      )
    },
    canDownloadRodoviario() {
      return (
        this.canDownload &&
        this.params.tipo_operacao == '2' &&
        !this.params.navio &&
        !this.params.servico_triado
      )
    },
    canDownloadExpedicaoRecebimento() {
      return (
        this.canDownload &&
        this.params.servico_triado === 'Transbordo Externo' &&
        false
      )
    },
    canDownloadExpedicaoFabrica() {
      return (
        this.canDownload &&
        this.params.tipo_operacao === 2 &&
        this.params.cliente === '28631030000115'
      )
    },
  },
  methods: {
    ...mapMutations('patio', ['SET_AGENDAMENTOS']),
    ...mapActions('patio', [
      'getAllAgendamentosByParams',
      'getAgendamentoRelatorio',
      'getRelatorioServico',
      'getRegioesColaborator',
      'getRelatorioServicoDetalhado',
    ]),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    handleGet(event) {
      this.options = event
      this.page_size = event.itemsPerPage
      this.handleGetAgendamentos(event.page)
    },
    async download() {
      console.log('download from RelatorioCargaDescargaListConcluidos')
      await this.getDownload()
    },
    async getDownload() {
      console.log('getDownload from RelatorioCargaDescargaListConcluidos')
      if (!this.canDownload) return
      if (
        this.empresaAtual.representantes.length > 0 &&
        this.params.regiao == undefined
      ) {
        await this.getRegioesColaborator()

        if (this.regioes.length > 0) {
          this.params.regiao = this.regioes.map((regiao) => regiao.public_id)
          this.params.representante = this.user.public_id
        }
      }
      this.$emit('loading')
      let data = []
      try {
        const indicadores = this.headers
          .filter((item) => item.value != 'actions')
          .map((item) => item.text)
          .join(',')
        const path = 'api/v1/relatorios/agendamentos/download/'
        const params = {
          proprietario: this.empresaAtual.public_id,
          status: 'CONCLUÍDO',
          ...this.params,
          indicadores,
        }
        const url = getUrl(path, params)
        await downloadUrl(url)
      } catch (error) {
        console.log(error)
        this.errorMessage(
          'Não foi possível baixar a planilha. Tente novamente mais tarde.'
        )
      } finally {
        this.$emit('loaded')
      }
      return data
    },
    async handleGetAgendamentos(page = 1, new_page_size = undefined) {
      if (!Object.keys(this.params).length) return

      if (new_page_size) {
        this.page_size = new_page_size
      }
      const page_size = this.page_size
      this.$emit('loading')
      try {
        const { sortBy, sortDesc } = this.options
        let ordering
        if (
          sortBy &&
          sortBy.length === 1 &&
          sortDesc &&
          sortDesc.length === 1
        ) {
          ordering = sortBy[0]
          ordering = sortDesc[0] ? '-' + ordering : ordering
          ordering = ordering.replaceAll('.', '__')
        }
        if (
          this.empresaAtual.representantes.length > 0 &&
          this.params.regiao == undefined
        ) {
          await this.getRegioesColaborator()
          if (this.regioes.length > 0) {
            this.params.regiao = this.regioes.map((regiao) => regiao.public_id)
            this.params.representante = this.user.public_id
          }
        }
        const { results, count, resumo } = await this.getAgendamentoRelatorio({
          page,
          page_size: (page_size === -1 && this.dataTotal) || page_size,
          ordering,
          proprietario: this.empresaAtual.public_id,
          ...this.params,
        })
        this.resumo = resumo
        this.data = results
        this.dataTotal = count
      } catch (e) {
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      } finally {
        this.$emit('loaded')
      }
    },
    async getCustomDownload() {
      // Relatório de Recebimento Navio
      if (!this.canDownload) return
      this.$emit('loading')
      try {
        const indicadores = this.headers
          .filter((item) => item.value != 'actions')
          .map((item) => item.text)
          .join(',')

        const path = 'api/v1/relatorios/agendamentos/download-custom/'
        const params = {
          proprietario: this.empresaAtual.public_id,
          status: 'CONCLUÍDO',
          ...this.params,
          indicadores,
          report_type: 'navio',
        }
        console.log('params being sent to backend:', {
          path,
          params,
          fullUrl: getUrl(path, params),
        })

        const url = getUrl(path, params)
        await downloadUrl(url)
      } catch (error) {
        console.error(error)
        this.errorMessage(
          'Não foi possível gerar o relatório. Tente novamente mais tarde.'
        )
      } finally {
        this.$emit('loaded')
      }
    },
    async getCustomCargaDownload() {
      // Relatório de Expedição de Carga
      if (!this.canDownload) return
      this.$emit('loading')
      try {
        const indicadores = this.headers
          .filter((item) => item.value != 'actions')
          .map((item) => item.text)
          .join(',')

        const path = 'api/v1/relatorios/agendamentos/download-custom/'
        const params = {
          proprietario: this.empresaAtual.public_id,
          status: 'CONCLUÍDO',
          ...this.params,
          indicadores,
          report_type: 'carga',
        }
        const url = getUrl(path, params)
        await downloadUrl(url)
      } catch (error) {
        console.error(error)
        this.errorMessage(
          'Não foi possível gerar o relatório. Tente novamente mais tarde.'
        )
      } finally {
        this.$emit('loaded')
      }
    },
    async getCustomTransbordoDownload() {
      // Relatório de Transbordo Interno
      if (!this.canDownload) return
      this.$emit('loading')
      try {
        const indicadores = this.headers
          .filter((item) => item.value != 'actions')
          .map((item) => item.text)
          .join(',')

        const path = 'api/v1/relatorios/agendamentos/download-custom/'
        const params = {
          proprietario: this.empresaAtual.public_id,
          status: 'CONCLUÍDO',
          ...this.params,
          indicadores,
          report_type: 'transbordo',
        }
        const url = getUrl(path, params)
        await downloadUrl(url)
      } catch (error) {
        console.error(error)
        this.errorMessage(
          'Não foi possível gerar o relatório. Tente novamente mais tarde.'
        )
      } finally {
        this.$emit('loaded')
      }
    },
    async getCustomRodoviarioDownload() {
      // Relatório de Recebimento Rodoviário
      if (!this.canDownload) return
      this.$emit('loading')
      try {
        const indicadores = this.headers
          .filter((item) => item.value != 'actions')
          .map((item) => item.text)
          .join(',')

        const path = 'api/v1/relatorios/agendamentos/download-custom/'
        const params = {
          proprietario: this.empresaAtual.public_id,
          status: 'CONCLUÍDO',
          ...this.params,
          indicadores,
          report_type: 'recebimento_rodoviario',
        }
        const url = getUrl(path, params)
        await downloadUrl(url)
      } catch (error) {
        console.error(error)
        this.errorMessage(
          'Não foi possível gerar o relatório. Tente novamente mais tarde.'
        )
      } finally {
        this.$emit('loaded')
      }
    },
    async getCustomExpedicaoRecebimentoDownload() {
      // Relatório de Expedição e Recebimento
      if (!this.canDownload) return
      this.$emit('loading')
      try {
        const indicadores = this.headers
          .filter((item) => item.value != 'actions')
          .map((item) => item.text)
          .join(',')

        const path = 'api/v1/relatorios/agendamentos/download-custom/'
        const params = {
          proprietario: this.empresaAtual.public_id,
          status: 'CONCLUÍDO',
          ...this.params,
          indicadores,
          report_type: 'expedicao_recebimento',
        }
        const url = getUrl(path, params)
        await downloadUrl(url)
      } catch (error) {
        console.error(error)
        this.errorMessage(
          'Não foi possível gerar o relatório. Tente novamente mais tarde.'
        )
      } finally {
        this.$emit('loaded')
      }
    },
    async getCustomAcertoPesoDownload() {
      // Relatório de Acerto de Peso
      if (!this.canDownload) return
      this.$emit('loading')
      try {
        const indicadores = this.headers
          .filter((item) => item.value != 'actions')
          .map((item) => item.text)
          .join(',')

        const path = 'api/v1/relatorios/agendamentos/download-custom/'
        const params = {
          proprietario: this.empresaAtual.public_id,
          status: 'CONCLUÍDO',
          ...this.params,
          indicadores,
          report_type: 'acerto_peso',
        }
        const url = getUrl(path, params)
        await downloadUrl(url)
      } catch (error) {
        console.error(error)
        this.errorMessage(
          'Não foi possível gerar o relatório. Tente novamente mais tarde.'
        )
      } finally {
        this.$emit('loaded')
      }
    },
    async getCustomExpedicaoFabricaDownload() {
      // Relatório de Expedição de Fabrica
      if (!this.canDownload) return
      this.$emit('loading')
      try {
        const indicadores = this.headers
          .filter((item) => item.value != 'actions')
          .map((item) => item.text)
          .join(',')

        const path = 'api/v1/relatorios/agendamentos/download-custom/'
        const params = {
          proprietario: this.empresaAtual.public_id,
          status: 'CONCLUÍDO',
          ...this.params,
          indicadores,
          report_type: 'expedicao_fabrica',
        }
        const url = getUrl(path, params)
        await downloadUrl(url)
      } catch (error) {
        console.error(error)
        this.errorMessage(
          'Não foi possível gerar o relatório. Tente novamente mais tarde.'
        )
      } finally {
        this.$emit('loaded')
      }
    },
    search() {
      this.handleGetAgendamentos()
    },
  },
}
</script>
