export const data = () => ({
  optionsSelected: [],
  options: [
    {
      id: 10,
      name: 'Data Do Agendamento',
      children: [
        {
          id: 'data_agendamento_inicio',
          name: 'De',
        },
        {
          id: 'data_agendamento_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 20,
      name: 'Data Da Triagem',
      children: [
        {
          id: 'data_triagem_inicio',
          name: 'De',
        },
        {
          id: 'data_triagem_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 30,
      name: 'Data Da Cota',
      children: [
        {
          id: 'data_cota_inicio',
          name: 'De',
        },
        {
          id: 'data_cota_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 40,
      name: 'Data Do Pedido',
      children: [
        {
          id: 'data_pedido_inicio',
          name: 'De',
        },
        {
          id: 'data_pedido_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 50,
      name: 'Data Da Saída  Do Patio',
      children: [
        {
          id: 'data_saida_patio_inicio',
          name: 'De',
        },
        {
          id: 'data_saida_patio_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 60,
      name: 'Data Da Conclusão',
      children: [
        {
          id: 'data_conclusao_inicio',
          name: 'De',
        },
        {
          id: 'data_conclusao_fim',
          name: 'Até',
        },
      ],
    },
    {
      id: 70,
      name: 'Fornecedor',
      children: [
        {
          id: 'fornecedor',
          name: 'Fornecedor',
        },
        {
          id: 'produto',
          name: 'Produto',
        },
        {
          id: 'sub_produto',
          name: 'Sub Produto',
        },
      ],
    },
    {
      id: 80,
      name: 'Contratos',
      children: [
        {
          id: 'terminal',
          name: 'Terminal',
        },
        {
          id: 'cliente',
          name: 'Cliente',
        },
        {
          id: 'transportadora',
          name: 'Transportadora',
        },
      ],
    },
    {
      id: 90,
      name: 'Região',
      children: [
        {
          id: 'regiao',
          name: 'Região',
        },
        {
          id: 'representante',
          name: 'Representante',
        },
      ],
    },
    {
      id: 100,
      name: 'Veículo',
      children: [
        {
          id: 'motorista',
          name: 'Motorista',
        },
        {
          id: 'placa_cavalo',
          name: 'Placa',
        },
        {
          id: 'eixo',
          name: 'Eixo',
        },
      ],
    },
    {
      id: 110,
      name: 'Navio',
      children: [
        {
          id: 'navio',
          name: 'Navio',
        },
      ],
    },
    {
      id: 120,
      name: 'Tipo de Operação',
      children: [
        {
          id: 'tipo_operacao',
          name: 'Tipo de Operação',
        },
      ],
    },
    {
      id: 130,
      name: 'Serviços',
      children: [
        {
          id: 'servico_triado',
          name: 'Serviço',
        },
      ],
    },
  ],
})
