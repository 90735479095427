<template>
  <v-card>
    <v-card-title v-if="canList" class="d-flex justify-space-between">
      <span>Filtros </span>
      <div>
        <v-btn
          class="mx-2"
          title="Configurar filtros"
          color="primary"
          @click="handleShowConfig"
        >
          <v-icon>mdi-cog</v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          title="Filtrar"
          color="secondary"
          @click="handleShowFilter"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <v-dialog v-model="showFilter" max-width="1200px">
      <relatorio-carga-descarga-list-filtro-modal
        v-if="showFilter"
        @close="showFilter = false"
        @search="search"
      />
    </v-dialog>
    <v-dialog v-model="showConfig" max-width="500px">
      <relatorio-carga-descarga-list-filtro-config
        v-if="showConfig"
        @close="showConfig = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

import RelatorioCargaDescargaListFiltroModal from './RelatorioCargaDescargaListFiltroModal/Componente.vue'
import RelatorioCargaDescargaListFiltroConfig from './RelatorioCargaDescargaListFiltroConfig/Componente.vue'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'RelatorioCargaDescargaListFiltro',
  components: {
    RelatorioCargaDescargaListFiltroModal,
    RelatorioCargaDescargaListFiltroConfig,
  },
  data() {
    return {
      showFilter: false,
      showConfig: false,
      loading: false,
    }
  },
  methods: {
    ...mapActions('patio', ['getAllAgendamentosByParams']),

    handleShowFilter() {
      this.showFilter = true
      this.showConfig = false
    },
    handleShowConfig() {
      this.showFilter = false
      this.showConfig = true
    },
    async search() {
      this.showFilter = false
      this.$emit('search')
    },
    handleCustomCargaDownload() {
      this.$emit('getCustomCargaDownload')
    },
    handleCustomTransbordoDownload() {
      this.$emit('getCustomTransbordoDownload')
    },
    handleCustomRodoviarioDownload() {
      this.$emit('getCustomRodoviarioDownload')
    },
    handleCustomExpedicaoRecebimentoDownload() {
      this.$emit('getCustomExpedicaoRecebimentoDownload')
    },
    handleCustomAcertoPesoDownload() {
      this.$emit('getCustomAcertoPesoDownload')
    },
    handleCustomExpedicaoFabricaDownload() {
      this.$emit('getCustomExpedicaoFabricaDownload')
    },
  },
  computed: {
    permission() {
      return Sequences.CargaDescarga.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
  },
}
</script>
