<template>
  <v-container>
    <!-- Tabela de Dias -->
    <v-data-table
      :headers="headersDia"
      :items="data"
      :single-expand="true"
      :expanded.sync="expandedDia"
      :hide-default-footer="true"
      :items-per-page="-1"
      item-key="idDia"
      show-expand
      class="elevation-1"
    >
      <!-- Exibe o Dia e a Quantidade Total -->
      <template v-slot:[`item.dia`]="{ item }">
        {{ item.dia }}
      </template>

      <template v-slot:[`item.quantidade`]="{ item }">
        {{ mask_peso(item.quantidade) }}
      </template>

      <!-- Rodapé da Tabela de Dias -->
      <template v-slot:footer>
        <div class="mt-10">
          <v-data-table
            :headers="headerstotalDia"
            :items="[{ descricao: 'Total', quantidade: totalQuantidadeDia }]"
            :hide-default-footer="true"
            :items-per-page="-1"
            class="elevation-1"
          >
            <template v-slot:[`item.quantidade`]="{ item }">
              {{ mask_peso(item.quantidade) }}
            </template>
          </v-data-table>
        </div>
      </template>

      <!-- Tabela de Produtos expandida -->
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="relatorio">
          <v-data-table
            :headers="headersProduto"
            :items="item.produtos"
            :hide-default-footer="true"
            :items-per-page="-1"
            item-key="idProduto"
            show-expand
            class="elevation-1 mt-4"
          >
            <!-- Exibe o Produto e a Quantidade -->
            <template v-slot:[`item.descricao`]="{ item }">
              {{ item.descricao }}
            </template>

            <template v-slot:[`item.quantidade`]="{ item }">
              {{ mask_peso(item.quantidade) }}
            </template>

            <!-- Rodapé da Tabela de Produtos -->
            <template v-slot:footer>
              <div class="mt-10">
                <v-data-table
                  :headers="headerstotalProduto"
                  :items="[
                    {
                      descricao: 'Total',
                      quantidade: totalQuantidadeProduto(item),
                    },
                  ]"
                  :hide-default-footer="true"
                  :items-per-page="-1"
                  class="elevation-1"
                >
                  <template v-slot:[`item.quantidade`]="{ item }">
                    {{ mask_peso(item.quantidade) }}
                  </template>
                </v-data-table>
              </div>
            </template>

            <!-- Tabela de Embalagens expandida -->
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" class="relatorio">
                <v-data-table
                  :headers="headersEmbalagem"
                  :items="item.EMBALAGENS"
                  :hide-default-footer="true"
                  :items-per-page="-1"
                  item-key="idEmbalagem"
                  class="elevation-1 mt-4"
                >
                  <!-- Exibe a Embalagem e a Quantidade -->
                  <template v-slot:[`item.descricao`]="{ item }">
                    {{ item.descricao }}
                  </template>

                  <template v-slot:[`item.quantidade`]="{ item }">
                    {{ mask_peso(item.quantidade) }}
                  </template>

                  <!-- Rodapé da Tabela de Embalagens -->
                  <template v-slot:footer>
                    <div class="mt-10">
                      <v-data-table
                        :headers="headerstotalEmbalagem"
                        :items="[
                          {
                            descricao: 'Total',
                            quantidade: totalQuantidadeEmbalagem(item),
                          },
                        ]"
                        :hide-default-footer="true"
                        :items-per-page="-1"
                        class="elevation-1"
                      >
                        <template v-slot:[`item.quantidade`]="{ item }">
                          {{ mask_peso(item.quantidade) }}
                        </template>
                      </v-data-table>
                    </div>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import RelatorioApi from '@/services/relatorios'

export default {
  name: 'ProdutoEmbalagemNestedTables',
  data() {
    return {
      data: [], // Armazena os dados retornados pela API
      expandedDia: [], // Controla quais dias estão expandidos
      headersDia: [
        { text: 'Dia', value: 'dia' },
        { text: 'Quantidade Total', value: 'quantidade' },
      ],
      headersProduto: [
        { text: 'Produto', value: 'descricao' },
        { text: 'Quantidade', value: 'quantidade' },
      ],
      headersEmbalagem: [
        { text: 'Embalagem', value: 'descricao' },
        { text: 'Quantidade', value: 'quantidade' },
      ],
      headerstotalDia: [
        { text: 'Descrição', value: 'descricao' },
        { text: 'Quantidade Total', value: 'quantidade' },
      ],
      headerstotalProduto: [
        { text: 'Descrição', value: 'descricao' },
        { text: 'Quantidade Total', value: 'quantidade' },
      ],
      headerstotalEmbalagem: [
        { text: 'Descrição', value: 'descricao' },
        { text: 'Quantidade Total', value: 'quantidade' },
      ],
    }
  },
  async created() {
    await this.atualizaDados()
  },
  computed: {
    ...mapState('relatorioPedidos', ['params']),
    totalQuantidadeDia() {
      // Calcula a somatória da quantidade total dos dias
      return this.data.reduce((sum, item) => sum + item.quantidade, 0)
    },
    totalQuantidadeProduto() {
      return (item) => {
        // Calcula a somatória da quantidade total dos produtos no item expandido
        return item.produtos.reduce(
          (sum, produto) => sum + produto.quantidade,
          0
        )
      }
    },
    totalQuantidadeEmbalagem() {
      return (item) => {
        // Calcula a somatória da quantidade total das embalagens no item expandido
        return item.EMBALAGENS.reduce(
          (sum, embalagem) => sum + embalagem.quantidade,
          0
        )
      }
    },
  },
  methods: {
    async atualizaDados(params = null) {
      try {
        if (params == null) {
          params = this.params
        }
        const data = await RelatorioApi.getRelatorioPedidos({
          ...params,
        })
        this.data = data

        console.log('Dados atualizados:', this.data)
      } catch (error) {
        console.error('Erro ao buscar dados:', error)
      }
    },
    mask_peso(quantidade) {
      if (!quantidade) return '0'
      console.log('dsds')
      return quantidade.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
    },
  },
}
</script>
