export function data() {
  return {
    settingFilters: false,
    dependents: {
      fornecedor: ['produto', 'sub_produto'],
    },
    gates: [],
    params: {
      data_agendamento_inicio: {
        value: '',
        visible: false,
      },
      data_agendamento_fim: {
        value: '',
        visible: false,
        until: true,
      },
      data_triagem_inicio: {
        value: '',
        visible: false,
      },
      data_triagem_fim: {
        value: '',
        visible: false,
        until: true,
      },
      data_cota_inicio: {
        value: '',
        visible: false,
      },
      data_cota_fim: {
        value: '',
        visible: false,
        until: true,
      },
      data_pedido_inicio: {
        value: '',
        visible: false,
      },
      data_pedido_fim: {
        value: '',
        visible: false,
        until: true,
      },
      data_saida_patio_inicio: {
        value: '',
        visible: false,
      },
      data_saida_patio_fim: {
        value: '',
        visible: false,
        until: true,
      },
      data_conclusao_inicio: {
        value: '',
        visible: false,
      },
      data_conclusao_fim: {
        value: '',
        visible: false,
        until: true,
      },
      gate: {
        value: '',
        visible: false,
      },
      proprietario: {
        value: '',
        visible: false,
      },
      fornecedor: {
        value: '',
        visible: false,
        get: this.getFornecedores,
      },
      produto: {
        value: '',
        visible: false,
      },
      sub_produto: {
        value: '',
        visible: false,
      },
      cliente: {
        value: '',
        visible: false,
        get: this.getClientes,
      },
      transportadora: {
        value: '',
        visible: false,
        get: this.getTransportadoras,
      },
      terminal: {
        value: '',
        visible: false,
        get: this.getContratos,
      },
      regiao: {
        value: '',
        visible: false,
        get: this.getRegioes,
      },
      representante: {
        value: '',
        visible: false,
        get: this.getRepresentantes,
      },
      motorista: {
        value: '',
        visible: false,
        get: this.getMotoristas,
      },
      placa_cavalo: {
        value: '',
        visible: false,
      },
      eixo: {
        value: '',
        visible: false,
      },
      navio: {
        value: '',
        visible: false,
      },
      tipo_operacao: {
        visible: false,
        value: '',
      },
      servico_triado: {
        visible: false,
        value: '',
      },
    },
    servicos: [],
  }
}
