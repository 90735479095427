<template>
  <v-card class="pa-2">
    <v-card-title class="d-flex justify-space-between">
      <span>Configuração</span>
      <v-btn color="error" @click="clear">Limpar</v-btn>
    </v-card-title>
    <v-card-text>
      <v-treeview
        v-model="optionsSelected"
        selectable
        :items="filteredOptions"
      ></v-treeview>
      <span class="error--text"
        >*Somente os itens selecionados aparecerão na tela de filtro</span
      >
    </v-card-text>
    <v-card-actions class="d-flex justify-space-between">
      <v-btn plain @click="close">Cancelar</v-btn>
      <v-btn color="primary" class="px-4" @click="save">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { data } from './_data.js'
import { mapState, mapActions } from 'vuex'
import { LOCAL_STORAGE_ID } from '@/utils/relatorioCargaDescarga'

export default {
  name: 'RelatorioCargaDescargaListFiltroConfig',
  data,
  computed: {
    ...mapState('auth', ['empresaAtual']),

    filteredOptions() {
      const allowedCnpjs = [
        '15011519000304',
        '15011519000142',
        '28631030000115',
      ]

      const isAllowed = allowedCnpjs.includes(this.empresaAtual?.cnpj)

      return this.options.filter((option) => {
        // filter out navio, tipo operação and serviços for non-allowed companies
        if (!isAllowed && [110, 120, 130].includes(option.id)) {
          return false
        }
        return true
      })
    },
  },
  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),

    clear() {
      this.optionsSelected.splice(0)
      localStorage.setItem(LOCAL_STORAGE_ID, JSON.stringify([]))
      this.successMessage('Configuração reiniciada com sucesso!')
    },
    save() {
      localStorage.setItem(
        LOCAL_STORAGE_ID,
        JSON.stringify(this.optionsSelected)
      )
      this.close()
      this.successMessage('Configuração salva com sucesso!')
    },
    close() {
      this.$emit('close')
    },
  },
  created() {
    this.optionsSelected =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_ID)) || []
  },
}
</script>
