import { mapState } from 'vuex'
import { BasicPermissions } from '@/utils/permissions'
import Perfil from '@/utils/perfil'
import { Sequences, CustomPermissions } from '@/utils/permissions'

export const computed = {
  ...mapState('auth', ['user', 'empresaAtual', 'perfilAtual']),
  ...mapState('patio', ['motivoscancelamento']),
  titlePesoExato() {
    let placa = '-'
    let tipo = 'exato'
    if (this.agendamentoPesoExato) {
      if (this.agendamentoPesoExato.veiculo) {
        placa = this.agendamentoPesoExato.veiculo.placa_cavalo
      }
      if (this.agendamentoPesoExato.extra) {
        if (this.agendamentoPesoExato.extra.agendamento) {
          if (this.agendamentoPesoExato.extra.agendamento.peso_exato) {
            tipo = 'normal'
          }
        }
      }
    }
    return `Confirmar agendamento (${placa}) como peso ${tipo}?`
  },
  ehFornecedor() {
    return this.empresaAtual.tiposContrato.includes(Perfil.Fornecedor)
  },
  ehGate() {
    return this.empresaAtual.gate && this.perfilAtual == Perfil.Unidade
  },
  orderedListagem() {
    this.selecionados = []
    return this.data.sort((a, b) =>
      this.getTempo(a.triado_em) > this.getTempo(b.triado_em) ? -1 : 1
    )
  },
  quantidadeSelecionada() {
    if (Array.isArray(this.selecionados) && 'length' in this.selecionados) {
      return this.selecionados.length
    } else {
      return 0
    }
  },
  sequenceString() {
    return this.isRelatorio
      ? Sequences.CargaDescarga.toString()
      : Sequences.GerenciamentoServico.toString()
  },

  isRelatorio() {
    return this.$route.name === 'CargaDescargaRelatorio'
  },
  canCancel() {
    return this.$canDo(CustomPermissions.CANCELAR, this.sequenceString)
  },

  canCancelService() {
    return this.$canDo(CustomPermissions.CANCELAR_SERVICO, this.sequenceString)
  },
  canAdditionalService() {
    return this.$canDo(CustomPermissions.SERVICO_ADICIONAL, this.sequenceString)
  },
  canPause() {
    return this.$canDo(CustomPermissions.PAUSAR, this.sequenceString)
  },
  canWeigh() {
    return this.$canDo(CustomPermissions.PESAR, this.sequenceString)
  },
  canAdvanceSteps() {
    return this.$canDo(CustomPermissions.AVANCAR_ETAPAS, this.sequenceString)
  },

  canEntradaBuffer() {
    return this.$canDo(CustomPermissions.ENTRADA_BUFFER, this.sequenceString)
  },

  canSaidaBuffer() {
    return this.$canDo(CustomPermissions.SAIDA_BUFFER, this.sequenceString)
  },

  canMagnifyingGlass() {
    return this.$canDo(CustomPermissions.MAGNIFYING_GLASS, this.sequenceString)
  },
  canBackoffSteps() {
    return this.$canDo(CustomPermissions.RECUAR_ETAPAS, this.sequenceString)
  },
  canViewScheduleReceipt() {
    return this.$canDo(
      CustomPermissions.VISUALIZAR_COMPROVANTE_AGENDAMENTO,
      this.sequenceString
    )
  },
  canViewSortingReceipt() {
    return this.$canDo(
      CustomPermissions.VISUALIZAR_COMPROVANTE_TRIAGEM,
      this.sequenceString
    )
  },
  canViewCleaningReceipt() {
    return this.$canDo(
      CustomPermissions.VISUALIZAR_COMPROVANTE_LIMPEZA,
      this.sequenceString
    )
  },
  canSendClassification() {
    return this.$canDo(
      CustomPermissions.ENVIAR_CLASSIFICACAO,
      this.sequenceString
    )
  },
  canEnviarCadastroMotorista() {
    return this.$canDo(
      CustomPermissions.ENVIAR_CADASTRO_MOTORISTA,
      this.sequenceString
    )
  },
  canBypassDate() {
    return this.$canDo(
      CustomPermissions.IGNORAR_DATA_TRIAGEM,
      this.sequenceString
    )
  },
  isRelatorioMaster() {
    const allowedCnpjs = [
      '00000000000001',
      '15011519000304',
      '15011519000142',
      '28631030000115',
    ]
    return allowedCnpjs.includes(this.empresaAtual?.cnpj)
  },
  canChangeQuota() {
    return this.$canDo(CustomPermissions.CHANGE_QUOTA, this.sequenceString)
  },
  canList() {
    return this.$canDo(BasicPermissions.VISUALIZAR, this.sequenceString)
  },
  canCreate() {
    return this.$canDo(BasicPermissions.CADASTRAR, this.sequenceString)
  },
  canUpdate() {
    return this.$canDo(BasicPermissions.EDITAR, this.sequenceString)
  },
  canDelete() {
    return this.$canDo(BasicPermissions.EXCLUIR, this.sequenceString)
  },
}
