<template>
  <v-container fluid>
    <BaseBreadcrumb
      :tela="permission"
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col>
        <relatorio-carga-descarga-list-filtro @search="handleGetAgendamentos" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Relatório de Carga/Descarga">
          <v-dialog v-model="showDocumentos" width="700px">
            <v-card>
              <documentos />
            </v-card>
          </v-dialog>
          <v-dialog v-model="showComprovante" width="700px">
            <v-card>
              <comprovante />
            </v-card>
          </v-dialog>
          <v-dialog v-model="showComprovanteAgendamento" fullscreen>
            <v-card>
              <comprovante-agendamento />
            </v-card>
          </v-dialog>

          <v-dialog v-model="showComprovanteLaudo">
            <v-card>
              <comprovante-laudo />
            </v-card>
          </v-dialog>

          <v-dialog v-model="showComprovantes" width="700px">
            <v-card>
              <comprovantes />
            </v-card>
          </v-dialog>

          <v-dialog v-model="showLogsIntegracoes">
            <v-card>
              <LogsIntegracoes />
            </v-card>
          </v-dialog>

          <template slot="right">
            <v-dialog v-model="dialogIndicadores" max-width="500px">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-if="canList"
                  color="primary"
                  dark
                  class="ml-2 mb-2"
                  v-on="on"
                  >Indicadores</v-btn
                >
              </template>
              <indicadores-gerencimento-servico
                :headers="headers"
                @close="closeDialogIndicadores"
              />
            </v-dialog>
            <v-btn
              v-if="canList"
              color="primary"
              dark
              class="ml-2 mb-2"
              @click="handleGetAgendamentos"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>

          <relatorio-carga-descarga-list
            :headers="headersComputed"
            :isRelatorio="true"
            ref="listagem"
          />

          <div v-if="!canList" align="center">
            <span class="red--text"
              >Você não tem permissão para visualizar as informações desta
              tela</span
            >
          </div>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { BasicPermissions, Sequences } from '@/utils/permissions'

import RelatorioCargaDescargaList from '@/components/patio/relatorioCargaDescarga/RelatorioCargaDescargaList.vue'
import RelatorioCargaDescargaListFiltro from '@/components/patio/relatorioCargaDescarga/RelatorioCargaDescargaListFiltro.vue'
import Comprovantes from '@/components/patio/NovoAgendamento/comprovantes/Componente.vue'
import IndicadoresGerencimentoServico from '@/components/patio/GerenciamentoServico/IndicadoresGerencimentoServico.vue'
import Comprovante from '@/components/patio/NovoAgendamento/comprovante/Componente.vue'
import ComprovanteAgendamento from '@/components/patio/NovoAgendamento/comprovanteAgendamento/Componente.vue'
import ComprovanteLaudo from '@/components/patio/NovoAgendamento/comprovanteLaudo/Componente.vue'
import Documentos from '@/components/patio/NovoAgendamento/documentos/Componente.vue'
import LogsIntegracoes from '@/components/patio/NovoAgendamento/logsIntegracoes/Componente.vue'

import { data } from './_data'

const HEADERS_RELATORIO_CARGA_DESCARGA_LOCALSTORAGE =
  'headersRelatorioCargaDescarga'

export default {
  name: 'RelatorioCargaDescarga',
  provide() {
    return {
      visualizarDocumentos: this.visualizarDocumentos,
      visualizarComprovantes: this.visualizarComprovantes,
      fecharDocumentos: this.fecharDocumentos,
      visualizarComprovante: this.visualizarComprovante,
      visualizarComprovanteAgendamento: this.visualizarComprovanteAgendamento,
      visualizarComprovanteLaudo: this.visualizarComprovanteLaudo,
      fecharComprovanteAgendamento: this.fecharComprovanteAgendamento,
      fecharComprovanteServicos: this.fecharComprovanteServicos,
      visualizarLogsIntegracoes: this.visualizarLogsIntegracoes,
      fecharLogsIntegracoes: this.fecharLogsIntegracoes,
    }
  },
  components: {
    RelatorioCargaDescargaList,
    RelatorioCargaDescargaListFiltro,
    IndicadoresGerencimentoServico,
    Comprovante,
    ComprovanteAgendamento,
    ComprovanteLaudo,
    Documentos,
    Comprovantes,
    LogsIntegracoes,
  },
  data,
  computed: {
    ...mapState('auth', ['empresaAtual']),

    headersComputed() {
      return this.headers.filter((header) => !!header.habilited)
    },

    permission() {
      return Sequences.CargaDescarga.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  watch: {
    headersComputed() {
      localStorage.setItem(
        HEADERS_RELATORIO_CARGA_DESCARGA_LOCALSTORAGE,
        JSON.stringify(this.headers)
      )
    },
  },
  methods: {
    visualizarComprovante() {
      this.showComprovanteAgendamento = false
      this.showComprovanteLaudo = false
      this.showComprovante = true
    },
    visualizarComprovantes() {
      this.showComprovantes = true
    },
    visualizarComprovanteAgendamento() {
      this.showComprovante = false
      this.showComprovanteLaudo = false
      this.showComprovanteAgendamento = true
    },
    fecharComprovanteServicos() {
      this.showComprovantes = false
    },
    visualizarDocumentos() {
      this.showDocumentos = true
    },
    fecharDocumentos() {
      this.showDocumentos = false
      // this.$router.push({
      //   name: 'Gerenciamento de Serviços',
      // })
    },
    visualizarComprovanteLaudo() {
      this.showComprovante = false
      this.showComprovanteAgendamento = false
      this.showComprovanteLaudo = true
    },
    fecharComprovanteAgendamento() {
      this.showComprovanteAgendamento = false
    },
    visualizarLogsIntegracoes() {
      this.showLogsIntegracoes = true
    },
    fecharLogsIntegracoes() {
      this.showLogsIntegracoes = false
    },
    closeDialogIndicadores() {
      this.dialogIndicadores = false
    },
    async handleGetAgendamentos() {
      const { listagem } = this.$refs
      await listagem.handleGetAgendamentos()
    },
    setIndicadores() {
      const headersSaved = localStorage.getItem(
        HEADERS_RELATORIO_CARGA_DESCARGA_LOCALSTORAGE
      )

      const headersSavedParsed = JSON.parse(headersSaved)

      if (
        headersSaved &&
        Array.isArray(headersSavedParsed) &&
        headersSavedParsed.length > 0
      ) {
        const add = this.headers
          .map((header) => {
            if (
              !headersSavedParsed.some(
                (headerAlreadySaved) =>
                  headerAlreadySaved.value === header.value
              )
            )
              return header
          })
          .filter((header) => !!header)

        const continua = headersSavedParsed
          .map((headerAlreadySaved) => {
            if (
              this.headers.some(
                (header) => header.value === headerAlreadySaved.value
              )
            )
              return headerAlreadySaved
          })
          .filter((headerAlreadySaved) => !!headerAlreadySaved)

        this.headers = [...add, ...continua]
        localStorage.setItem(
          HEADERS_RELATORIO_CARGA_DESCARGA_LOCALSTORAGE,
          JSON.stringify([...add, ...continua])
        )
      } else {
        localStorage.setItem(
          HEADERS_RELATORIO_CARGA_DESCARGA_LOCALSTORAGE,
          JSON.stringify(this.headers)
        )
      }
    },
  },
  created() {
    if (this.canList) {
      this.setIndicadores()
    }
  },
}
</script>
