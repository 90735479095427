<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64" />
    </v-overlay>
    <relatorio-carga-descarga-list-concluidos
      :headers="headers"
      :tela="permission"
      :isRelatorio="true"
      ref="concluidos"
      @loading="loading = true"
      @loaded="loading = false"
    />
  </div>
</template>

<script>
import RelatorioCargaDescargaListConcluidos from './RelatorioCargaDescargaListConcluidos.vue'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  name: 'RelatorioCargaDescargaList',
  props: {
    headers: {
      type: Array,
    },
  },
  components: {
    RelatorioCargaDescargaListConcluidos,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    permission() {
      return Sequences.CargaDescarga.toString()
    },

    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
  },
  methods: {
    async handleGetAgendamentos() {
      await this.$refs.concluidos.handleGetAgendamentos()
    },
  },
}
</script>
